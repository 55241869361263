<template>
    <header
        :style="{ height: headerHeight }"
        :class="{
            'header--floating': isMenuFloating,
            'header--floating-down': isMenuFloatingDown,
        }"
    >
        <slot
            :hover-in="hoverIn"
            :hover-out="hoverOut"
            :set-height="setHeight"
            :on-toggle-menu="onToggleMenu"
            :hovering="hovering"
            :is-menu-open="isMenuOpen"
            :on-open-search="onOpenSearch"
            :on-search-blur="onSearchBlur"
            :is-search-open="isSearchOpen"
            :is-menu-floating="isMenuFloating"
        />
    </header>
</template>

<script>
    import debounce from 'lodash-es/debounce';

    export default {
        data() {
            return {
                height: 0,
                hovering: {},
                isHovering: false,
                isMenuOpen: false,
                isSearchOpen: false,
                isMenuFloating: false,
                isMenuFloatingDown: false,
                scrollTimeout: null,
            };
        },

        computed: {
            headerHeight() {
                const defaultHeight = window.innerWidth < 768 ? 5 : 7;
                return this.isHovering ? `calc(${defaultHeight}rem + ${this.height}px)` : null;
            },
        },

        mounted() {
            window.addEventListener('scroll', this.onScroll);
            this.onScroll();
        },

        beforeUnmount() {
            window.removeEventListener('scroll', this.onScroll);
        },

        methods: {
            hoverIn(nodeId) {
                this.hovering[nodeId] = true;
                this.isHovering = true;
            },
            hoverOut(nodeId) {
                this.hovering[nodeId] = false;
                this.isHovering = false;
            },
            setHeight(height) {
                this.height = height;
            },
            onToggleMenu() {
                this.isMenuOpen = !this.isMenuOpen;
            },
            onOpenSearch(event) {
                if (!this.isSearchOpen) {
                    event.preventDefault();
                    document.getElementById('search').focus();
                    this.isSearchOpen = true;

                    return false;
                }
            },
            onSearchBlur() {
                this.isSearchOpen = false;
            },
            onScroll: debounce(function() {
                this.isMenuFloating = (window.scrollY - this.$el.offsetHeight) > 0;

                clearTimeout(this.scrollTimeout);
                this.scrollTimeout = setTimeout(() => {
                    this.isMenuFloatingDown = this.isMenuFloating;
                    this.emitter.emit('menu-floating', this.isMenuFloatingDown);
                }, 100);
            }, 400),
        },
    };
</script>
