// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';
import 'aos/dist/aos.css';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// An excellent, responsive and lightweight lightbox
// import 'spotlight.js';

import AOS from 'aos';
import mitt from 'mitt';


// ================================================
// TUI Components
// ================================================

import Rot13Email from './components/Rot13Email.vue';
import Lightbox from './components/Lightbox.vue';
import MainHeader from './components/MainHeader.vue';
import Videos from './components/Videos.vue';
import Accordion from './components/Accordion.vue';
import Subscribe from './components/Subscribe.vue';
import AskQuestion from './components/AskQuestion.vue';
import CollapseTransition from './components/CollapseTransition.vue';
import TransplantCentres from './components/TransplantCentres.vue';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

import vClickOutside from 'click-outside-vue3';

import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    createHttpLink,
} from '@apollo/client/core';
import { createApolloProvider } from '@vue/apollo-option';

window.csrfToken = null;
const getToken = async() => {
    if (!window.csrfToken) {
        const result = await fetch('/actions/blitz/csrf/token');
        window.csrfToken = result.text();
    }

    return window.csrfToken;
};

// HTTP connection to the API
const httpLink = createHttpLink({
    // You should use an absolute URL here
    uri: '/api',
});

const authLink = new ApolloLink(async(operation, forward) => {
    operation.setContext({
        headers: {
            'X-CSRF-Token': await getToken(),
        },
    });

    return forward(operation);
});

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
});

const apolloProvider = createApolloProvider({
    defaultClient: apolloClient,
});

const app = createApp({
    delimiters: ['${', '}'],

    mounted() {
        setTimeout(() => {
            AOS.init({
                duration: 1000,
                disable: 'mobile',
            });
        }, 1000);
    },

    methods: {
        onSubscribe() {
            this.emitter.emit('open-subscribe');
        },
    },
});

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

app.use(vClickOutside);
app.use(apolloProvider);

app.component('Rot13Email', Rot13Email);
app.component('Lightbox', Lightbox);
app.component('MainHeader', MainHeader);
app.component('Videos', Videos);
app.component('Accordion', Accordion);
app.component('Subscribe', Subscribe);
app.component('AskQuestion', AskQuestion);
app.component('CollapseTransition', CollapseTransition);
app.component('TransplantCentres', TransplantCentres);

app.mount('#app');

// Remove our preload class from the body, preventing early CSS transitions
document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        document.body.className += ' loaded';
    }, 200);
});

if (sessionStorage.loaded) {
    document.body.className += ' loaded-before';
}

sessionStorage.loaded = true;
