<template>
    <transition name="fade">
        <slot :is-open="isOpen" :on-close="onClose" :vco-config="vcoConfig" />
    </transition>
</template>

<script>
    export default {
        data() {
            return {
                isOpen: false,
                vcoConfig: {
                    events: ['mousedown'],
                    handler: () => this.isOpen = false,
                },
            };
        },

        mounted() {
            this.emitter.on('open-subscribe', () => {
                this.isOpen = true;
            });

            if (location.pathname === '/subscribe') {
                window.localStorage.hasOpened = 'true';
                return;
            }

            if (window.localStorage.hasOpened) {
                return;
            }

            setTimeout(() => {
                this.isOpen = true;
                window.localStorage.hasOpened = 'true';
            }, 20 * 1000);
        },

        methods: {
            onClose() {
                clearTimeout(this.timeout);
                this.isOpen = false;
                window.localStorage.hasOpened = 'closed';
            },
        },
    };
</script>
