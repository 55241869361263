import { gql } from 'graphql-tag';

export const GET_VIDEOS_QUERY = gql`
    query GetVideos(
        $timeline: [QueryArgument!]!,
        $organ: [QueryArgument!]!,
        $featuring: [QueryArgument!]!,
        $category: [CategoryCriteriaInput!],
        $contributor: [EntryCriteriaInput!],
        $search: String
    ) {
        videos: entries(
            section: "videos",
            search: $search,
            timeline: $timeline,
            organ: $organ,
            featuring: $featuring,
            relatedToCategories: $category,
            relatedToEntries: $contributor,
            randomOrder: true
        ) {
            id
            title
            
            ... on videos_default_Entry {
                profile: videoProfile @transform(width: 112, format: "webp") {
                    id
                    url
                    title
                    srcset(sizes: ["1x", "2x", "3x"])
                }
                timeline
                description
                videoUrl
                interviewer(limit: 1) {
                    id
                    title
                    
                    ... on interviewer_Category {
                        description
                    }
                }
            }
        }
    }
`;
