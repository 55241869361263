<template>
    <button
        class="group text-left overflow-hidden"
        :class="[ bgColor[video.timeline], textColor[video.timeline] ]"
        @click.prevent="onPlay"
    >
        <div class="flex flex-col h-full items-end p-3 lg:p-6">
            <div class="flex flex-1 justify-end w-full mb-4">
                <div v-if="video.profile.length > 0" class="flex-1">
                    <img
                        class="lazyload block w-16 h-16 md:w-20 md:h-20 lg:w-28 lg:h-28 rounded-full"
                        :src="profilePlaceholder"
                        :data-src="video.profile[0].url"
                        :data-srcset="video.profile[0].srcset"
                        :alt="video.profile[0].title"
                    />
                </div>

                <div class="block w-10 h-10 ml-2 p-3 md:w-14 md:h-14 md:ml-4 md:p-4 appearance-none rounded-full border border-primary-500 text-primary-500 md:opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
                    <play-icon class="block w-auto h-full mx-auto" />
                </div>
            </div>

            <div class="w-full">
                <h3 class="font-bold leading-tight text-base md:text-2xl md:text-base lg:text-lg text-white group-hover:text-primary-500 transition-colors duration-300 ease-in-out mb-2">{{ videoTitle }}</h3>
                <p class="font-semibold leading-tight text-xs md:text-xs lg:text-sm" v-html="videoDescription" />
            </div>
        </div>
    </button>
</template>

<script>
    import PlayIcon from '../../img/icons/play.svg';
    import widont from '../filters/widont';
    import { svgPlaceholder } from '@utils/string';

    export default {
        components: {
            PlayIcon,
        },

        props: {
            video: {
                type: Object,
                required: true,
            },
        },

        emits: ['play'],

        data() {
            return {
                bgColor: {
                    before: 'bg-secondary-300',
                    during: 'bg-secondary-500',
                    after: 'bg-secondary-700',
                },
                textColor: {
                    before: 'text-secondary-700',
                    during: 'text-secondary-300',
                    after: 'text-secondary-300',
                },
                profilePlaceholder: svgPlaceholder(80, 80),
            };
        },

        computed: {
            videoAsset() {
                return this.video[0];
            },
            videoTitle() {
                if (window.innerWidth > 768) {
                    return widont(this.video.title);
                }

                return this.video.title;
            },
            videoDescription() {
                return this.video.description.replace(/\n/g, '<br />');
            },
        },

        methods: {
            onPlay() {
                this.$emit('play', this.video.videoUrl);
            },
        },
    };
</script>
