<template>
    <div>
        <slot :expanded="expanded" :toggle="onToggle" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                expanded: {},
            };
        },

        methods: {
            onToggle(id) {
                if (this.expanded[id]) {
                    this.expanded[id] = false;
                    return;
                }

                for (const n in this.expanded) {
                    if (Object.prototype.hasOwnProperty.call(this.expanded, n)) {
                        this.expanded[n] = false;
                    }
                }

                this.expanded[id] = true;
            },
        },
    };
</script>
