<template>
    <transition name="fade">
        <slot :is-open="isOpen" :on-close="onClose" :vco-config="vcoConfig" />
    </transition>
</template>

<script>
    export default {
        data() {
            return {
                isOpen: false,
                vcoConfig: {
                    events: ['mousedown'],
                    handler: () => this.isOpen = false,
                },
            };
        },

        mounted() {
            this.emitter.on('open-ask-question', () => {
                this.isOpen = true;
            });

            const form = this.$el.querySelector('form');
            form.addEventListener('onAfterFormieSubmit', () => {
                this.timeout = setTimeout(() => {
                    this.isOpen = false;
                }, 4 * 1000);
            });
        },

        methods: {
            onClose() {
                clearTimeout(this.timeout);
                this.isOpen = false;
            },
        },
    };
</script>
