<template>
    <div class="lg:flex lg:h-[80vh]">
        <div class="p-10 bg-secondary-600 text-white search-col">
            <form>
                <div class="mb-4">
                    <button
                        class="btn btn--primary"
                        type="button"
                        :disabled="!isNearbyEnabled || $apollo.queries.centres.loading"
                        :class="{
                            loading: $apollo.queries.centres.loading,
                        }"
                        @click.prevent="onSearchLocation"
                    >
                        Search Nearby Centres
                    </button>

                    <p v-if="!isNearbyEnabled" class="text-xs mt-1 text-red-800">You have disallowed location access in your browser.</p>
                </div>

                <div>
                    <label for="centre-search" class="block w-full text-sm mb-1">Or search by:</label>
                    <input
                        id="centre-search"
                        ref="search"
                        v-model="locationSearch"
                        type="search"
                        class="appearance-none block w-full text-black"
                        placeholder="City or Postcode"
                    />
                </div>

                <div class="mt-4">
                    <ul>
                        <li v-for="option in options" :key="option.value" class="filter-radio">
                            <input
                                :id="`centre-type-${option.value}`"
                                v-model="types"
                                :value="option.value"
                                type="checkbox"
                                class="filter__checkbox"
                            />
                            <label class="filter__label" :for="`centre-type-${option.value}`">{{ option.label }}</label>
                        </li>
                    </ul>
                </div>
            </form>
        </div>

        <div class="bg-white overflow-auto py-5 search-col">
            <ul>
                <li
                    v-for="(centre, index) in centres"
                    :key="centre.id"
                    class="flex last:mb-0 cursor-pointer hover:bg-black/5 px-10 py-5"
                    @click.prevent="onClickCentre(centre)"
                >
                    <div class="flex-shrink-none">
                        <div class="map-marker">
                            <span>{{ index + 1 }}</span>
                        </div>
                    </div>

                    <div class="flex-1 ml-4">
                        <h2 class="mt-1 font-semibold">{{ centre.title }}</h2>
                        <p class="text-sm text-gray-600">{{ centre.centreAddress.address }}</p>
                        <p v-if="centre.email" class="text-sm text-gray-600 mt-1">E: {{ centre.email }}</p>
                        <p v-if="centre.phone?.format" class="text-sm text-gray-600 mt-1">P: {{ centre.phone.format }}</p>
                    </div>
                </li>
            </ul>
        </div>

        <div class="relative pb-[100%] lg:pb-0 lg:flex-1">
            <div id="map" ref="map" class="absolute inset-0" />
        </div>
    </div>
</template>

<script>
    import { GET_CENTRES_QUERY } from '@/js/graphql/centres';
    import { toRaw } from 'vue';

    export default {
        props: {
            options: {
                type: Array,
                required: true,
            },
        },

        apollo: {
            centres: {
                query: GET_CENTRES_QUERY,
                debounce: 250,
                variables() {
                    const variables = {
                        order: 'title',
                        types: this.types,
                    };

                    if (this.place) {
                        variables.search = {
                            radius: 50,
                            country: 'AU',
                            unit: 'Kilometres',
                            coordinate: this.place,
                        };

                        variables.order = 'distance';
                    }

                    return variables;
                },
            },
        },

        data() {
            return {
                isNearbyEnabled: true,
                locationSearch: null,
                place: null,
                centres: [],
                types: [],
                markers: [],
                autocomplete: null,
                map: null,
            };
        },

        watch: {
            centres() {
                this.setMarkers();
            },
        },

        mounted() {
            this.loadAutocomplete();
            this.loadMap();
        },

        methods: {
            onSearchLocation() {
                navigator.geolocation.getCurrentPosition((position) => {
                    const { latitude, longitude } = position.coords;

                    this.place = {
                        lat: latitude,
                        lng: longitude,
                    };
                }, (error) => {
                    console.error(error);
                    this.isNearbyEnabled = false;
                });
            },
            async loadAutocomplete() {
                const { Autocomplete } = await google.maps.importLibrary('places');

                this.autocomplete = new Autocomplete(this.$refs.search, {
                    componentRestrictions: { country: ['au', 'nz'] },
                    types: ['locality', 'postal_code', 'administrative_area_level_3'],
                    fields: ['geometry'],
                });

                this.autocomplete.addListener('place_changed', () => {
                    const place = this.autocomplete.getPlace();
                    this.place = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                    };
                });
            },
            async loadMap() {
                const { Map } = await google.maps.importLibrary('maps');

                this.map = new Map(this.$refs.map, {
                    mapId: 'c35fae88e4a70a44',
                    center: { lat: -34.397, lng: 150.644 },
                    zoom: 8,
                });

                google.maps.event.addListener(this.map, 'bounds_changed', function(event) {
                    if (this.getZoom() > 16) {
                        this.setZoom(16);
                    }
                });

                await this.setMarkers();
            },
            async setMarkers() {
                const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');
                const { LatLngBounds } = await google.maps.importLibrary('core');

                if (!this.map) {
                    return;
                }

                // Clear all markers.
                for (const marker of this.markers) {
                    marker.setMap(null);
                }

                this.markers = [];
                const bounds = new LatLngBounds();

                for (let i = 0; i < this.centres.length; i++) {
                    const centre = this.centres[i];

                    const marker = new AdvancedMarkerElement({
                        position: {
                            lat: centre.centreAddress.lat,
                            lng: centre.centreAddress.lng,
                        },
                        map: toRaw(this.map),
                        title: centre.title,
                        content: this.buildMarker(i, centre),
                    });

                    this.markers.push(marker);
                    bounds.extend({
                        lat: centre.centreAddress.lat,
                        lng: centre.centreAddress.lng,
                    });
                }

                if (this.markers.length) {
                    this.map.fitBounds(bounds, 50);
                }
            },
            buildMarker(index, centre) {
                const content = document.createElement('div');
                content.className = 'map-marker';
                content.innerHTML = `<span>${index + 1}</span>`;

                return content;
            },
            onClickCentre(centre) {
                if (!this.map) {
                    return;
                }

                this.map.setZoom(17);

                this.map.panTo({
                    lat: centre.centreAddress.lat,
                    lng: centre.centreAddress.lng,
                });
            },
        },
    };
</script>

<style lang="postcss">
    .map-marker {
        @apply flex items-center text-center w-8 h-8 rounded-full bg-primary-500 text-black text-lg font-bold shadow-lg;

         > span {
            @apply w-full;
         }
    }

    .search-col {
        @screen lg {
            width: max(25%, 20rem);
        }
    }
</style>
