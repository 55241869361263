import { gql } from 'graphql-tag';

export const GET_RATING_FORM_QUERY = gql`
    query GetRatingForm {
        formieForm(handle: "ratings") {
            configJson
            csrfToken {
                name
                value
            }
        }
    }
`;
