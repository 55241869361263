<template>
    <button class="appearance-none rounded-none focus:outline-none" @click.prevent="onOpen">
        <slot />
    </button>
</template>

<script>
    import GLightbox from 'glightbox';

    export default {
        props: {
            provider: {
                type: String,
                default: '',
            },
            type: {
                type: String,
                required: true,
            },
            href: {
                type: String,
                required: true,
            },
            closeOnFinish: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                lightbox: null,
            };
        },

        mounted() {
            const elem = {
                href: this.href,
                type: this.type,
            };

            if (this.provider) {
                elem.source = this.provider;
            }

            this.lightbox = GLightbox({
                elements: [elem],
            });

            if (this.closeOnFinish) {
                this.lightbox.on('slide_changed', ({ current }) => {
                    const { player } = current;

                    if (player) {
                        player.on('ended', () => {
                            window.location = '/videos';
                        });
                    }
                });
            }
        },

        beforeUnmount() {
            this.lightbox.destroy();
        },

        methods: {
            onOpen() {
                this.lightbox.open();
            },
        },
    };
</script>

<style>
    .plyr__poster {
        z-index: -500000 !important;
    }
</style>
