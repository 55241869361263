import { gql } from 'graphql-tag';

export const GET_CENTRES_QUERY = gql`
    query GetCentres(
        $search: Ether_MapQuery,
        $types: [QueryArgument!],
        $order: String,
    ) {
        centres: entries(
            section: "transplantCentres",
            centreAddress: $search,
            centreType: $types,
            orderBy: $order,
        ) {
            id
            title

            ... on transplantCentres_default_Entry {
                email
                phone {
                    format(format: "INTERNATIONAL")
                }
                centreType
                centreAddress {
                    address
                    lat
                    lng
                    distance
                }
            }
        }
    }
`;
